/*
 * Base structure
 */
/*
 * Top navigation
 * Hide default border to remove 1px line.
 */
.navbar-fixed-top {
  border: 0; }

/*
 * Global add-ons
 */
.sub-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee; }

/*
 * Sidebar
 */
/* Hide for mobile, show later */
.sidebar {
  display: none; }

@media (min-width: 768px) {
  .sidebar {
    position: fixed !important;
    top: 51px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    /* Scrollable contents if viewport is shorter than content. */
    background-color: #f5f5f5;
    border-right: 1px solid #eee; } }

/* Sidebar navigation */
.nav-sidebar {
  margin-right: -21px;
  /* 20px padding + 1px border */
  margin-bottom: 20px;
  margin-left: -20px; }

.nav-sidebar > li > a {
  padding-right: 20px;
  padding-left: 20px;
  color: #222; }

.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
  color: #9d9d9d;
  background-color: #222; }

/*
 * Main content
 */
.main {
  padding: 20px; }

@media (min-width: 768px) {
  .main {
    padding-right: 40px;
    padding-left: 40px; } }

.main .page-header {
  margin-top: 0; }

/* collapse submenu in the sidebar */
.submenu {
  font-size: 75% !important; }

.submenu-item > a {
  color: #3f5b7d; }

/* Move down content because we have a fixed navbar that is 50px tall */
body {
  padding-top: 60px; }

.center {
  text-align: center; }

.fixed {
  position: fixed; }

.fill {
  margin-right: 2px !important;
  margin-left: 2px !important;
  width: 100% !important; }

hr {
  height: 1px;
  background-color: #555;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 75%; }

.intensity_0 {
  background-color: #bbbbbb !important;
  color: black !important; }

.intensity_1 {
  background-color: #90cc90 !important;
  color: black !important; }

.intensity_2 {
  background-color: #3cb371 !important;
  color: black !important; }

.intensity_3 {
  background-color: #006400 !important;
  color: ghostwhite !important; }

.intensity_4 {
  background-color: #90cc90 !important;
  color: black !important; }

.intensity_5 {
  background-color: #3cb371 !important;
  color: black !important; }

.intensity_6 {
  background-color: #006400 !important;
  color: ghostwhite !important; }

.keyTable {
  font-size: 70%;
  margin-bottom: 0;
  width: 100%;
  height: 60px;
  table-layout: fixed; }

.keyTableCell {
  padding: 0 !important;
  border: 0 !important;
  height: 30px; }

.indexTableHeading {
  font-size: 120% !important; }

.indexTableCell {
  vertical-align: middle !important;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 65%;
  padding: 0 !important;
  height: 30px; }

.indexTableRowCell {
  font-weight: bold !important;
  font-size: 7px !important; }

.indexTable {
  table-layout: fixed;
  width: 100% !important; }

.innerTableCell {
  padding: 0 !important; }

.innerTable {
  table-layout: fixed;
  height: 100%;
  width: 100%; }

.vmiddle {
  vertical-align: middle !important; }

.hmiddle {
  text-align: center !important; }

.yellow_line {
  background-color: #f0e68c; }

.min_width {
  width: 115px !important; }

.checkboxCell {
  width: 10px !important;
  font-size: 12px !important; }

.highlightCell {
  border: 2px solid #666 !important;
  width: 25px;
  height: 25px;
  float: left; }

.highlight {
  border-left: 2px solid #666 !important;
  border-right: 2px solid #666 !important; }

.highlight_first {
  border-top: 2px solid #666 !important; }

.highlight_last {
  border-bottom: 2px solid #666 !important; }

.table tr.selected td {
  background-color: #6AD; }

.navActive {
  font-weight: bold;
  color: ghostwhite !important; }

.tdLink a {
  width: 100%;
  height: 100%;
  display: block; }

.tdLink a:link {
  color: black !important; }

.tdLink a:visited {
  color: green; }

.tdLink a:hover {
  color: green !important;
  background-color: black; }

.tdLink a:active {
  color: blue; }

div.alphabet {
  position: relative;
  display: table;
  width: 100%;
  margin-bottom: 1em; }

div.alphabet span {
  display: table-cell;
  color: #3174c7;
  cursor: pointer;
  text-align: center;
  width: 3.5%; }

div.alphabet span:hover {
  text-decoration: underline; }

div.alphabet span.active {
  color: black; }

div.alphabet span.empty {
  color: red; }

div.alphabetInfo {
  display: block;
  position: absolute;
  background-color: #111;
  border-radius: 3px;
  color: white;
  top: 2em;
  height: 1.8em;
  padding-top: 0.4em;
  text-align: center;
  z-index: 1; }

/*# sourceMappingURL=app.css.map */
